import styled, { css } from 'styled-components'
import Card from '@veneer/core/dist/scripts/card'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Select from '@veneer/core/dist/scripts/select'
import CreditCard from '@veneer/core/dist/scripts/icons/icon_credit_card'
import { DirectDebit } from './directDebit'

export const StyledBillingAndAddress = styled.div`
  width: 100%;

  ${(props) => {
    if (
      ['tablet-landscape-container', 'desktop-container'].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        width: 100%;
        min-width: 594px;
      `
    }
  }}
`

export const StyledBillingTypeSelector = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  overflow-wrap: anywhere;
`

export const StyledSubtitle = styled.span`
  text-align: left;
  margin-bottom: 8px;
  letter-spacing: 0.8px;
  line-height: 20px;

  ${(props) => {
    const responsive = {
      'desktop-container': css`
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 28px;
      `,
      'tablet-landscape-container': css`
        font-size: 20px;
        letter-spacing: 0.9px;
        line-height: 28px;
      `
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledTypeSelectorCards = styled.div`
  display: flex;
  margin: 0px -8px;
  align-items: stretch;

  ${(props) => {
    if (
      ['tablet-landscape-container', 'desktop-container'].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        flex-direction: row;
      `
    } else {
      return css`
        flex-direction: column;
      `
    }
  }}
`

export const StyledTypeSelectorCard = styled(Card)`
  flex: 1 1;
  padding: 16px;
  margin: 0px 8px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) => {
    if (
      !['tablet-landscape-container', 'desktop-container'].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        margin: 8px;
      `
    }
  }}
`

export const StyledCardContent = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
`

export const StyledVeneerRadio = styled.div`
  flex: auto;

  > label {
    display: flex;
    align-items: start;
  }
`

export const StyledVeneerRadioContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 0;
  text-align: left;
  white-space: normal;
`

export const StyledTypeSelectorIcon = styled.div`
  vertical-align: middle;
  height: 32px;
`

export const StyledCreditCardIcon = styled(CreditCard)`
  margin-top: -4px;
`

export const StyledDirectDebitIcon = styled(DirectDebit)`
  margin-top: 4px;
`

export const StyledAddressSectionContainer = styled.div`
  width: 100%;
`

export const StyledPayPalShipping = styled.div`
  margin-top: 24px;

  span:first-child {
    display: block;
  }
`

export const StyledShippingInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 0 0;
  gap: 12px;

  > div {
    flex: 1 0 0;

    & > span {
      display: block;
    }
  }

  ${(props) => {
    const tabPortMobile = css`
      flex-direction: column;
    `

    const responsive = {
      'mobile-container': tabPortMobile,
      'tablet-portrait-container': tabPortMobile
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledAddressPreviewCard = styled(Card)`
  flex-basis: 50%;
`

export const StyledShippingCard = styled.div`
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  min-width: 184px;
`

export const StyledFullName = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 4px 0;
  display: block;
`

export const StyledShippingCardDetail = styled.p`
  margin: 0;
`

export const StyledFormFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => {
    const tabLandDesk = css`
      div:first-child {
        margin-right: 8px;
      }
    `

    const responsive = {
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}

  ${(props) => {
    const tabLandDesk = css`
      flex-direction: row;
      align-items: normal;
    `

    const responsive = {
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledTaxIdType = styled.a`
  display: inline-block;
  margin-top: 12px;
  cursor: pointer;
`

export const StyledTaxField = styled.div`
  width: 100%;
`

export const StyledTextBox = styled(TextBox)`
  margin: ${(props) => (props['$no-margin'] ? '0' : '8px 0')};
  width: 100%;

  ${(props) =>
    props.label === 'Umsatzsteuer-Identifikationsnummer (optional)' &&
    css`
      @media screen and (max-width: 500px) {
        label {
          overflow: visible;
          overflow-wrap: break-word;
          white-space: normal;
        }

        div {
          max-height: unset;
          height: 110px;
        }
      }
    `}
`

export const StyledTooltipText = styled.p`
  margin: 0;
  text-align: center;
  max-width: 132px;
`

export const StyledRightInfoIcon = styled.span`
  margin-right: 12px;
`

export const StyledSelect = styled(Select)`
  margin: 8px 0;
  width: 100%;

  ${(props) => {
    const tabLandDesk = css`
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    `

    const responsive = {
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledTaxpayerFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 16px;
  margin-top: 16px;

  div:first-child {
    margin-right: 16px;
  }

  ${(props) => {
    const tabPortMobile = css`
      display: flex;
      gap: 0;
      flex-direction: column;
      margin-top: 16px;

      div {
        margin-bottom: 16px;
      }

      div:last-child {
        margin: 0;
      }
    `

    const responsive = {
      'mobile-container': tabPortMobile,
      'tablet-portrait-container': tabPortMobile
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledAccountTypeSection = styled.div`
  width: 100%;
  margin-top: 24px;

  ${(props) => {
    const tabPortMobile = css`
      width: 100%;
      margin-top: 24px;

      > span {
        display: block;
      }
    `

    const responsive = {
      'mobile-container': tabPortMobile,
      'tablet-portrait-container': tabPortMobile
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledAccountTypeSelectorButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  flex-wrap: wrap;
  row-gap: 8px;

  > label {
    flex: 0 0 auto;

    &:first-child {
      margin-right: 16px;
    }
  }
`

export const StyledPaymentIcons = styled.span`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px 0;
  align-items: center;
`
